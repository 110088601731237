<template>
  <v-row>
    <v-col
      md="7"
      cols="12"
      class="d-flex flex-stretch"
    >
      <v-card
        v-if="results.length"
        density="compact"
        class="d-flex flex-column justify-space-between"
      >
        <v-toolbar
          density="compact"
          color="blue-grey-darken-1"
        >
          <v-toolbar-title>{{ $t('results.title') }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-column justify-center pa-2"
            >
              <v-table>
                <tbody>
                  <tr>
                    <th
                      class="text-h6 font-weight-regular"
                      colspan="2"
                    >
                      {{ sheetById(inputData.sheet).name }} {{ inputData.thickness }} mm
                    </th>
                    <td class="text-caption text-grey-darken-1">
                      {{ $t('results.info.sheet') }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-h6 font-weight-regular">
                      q
                    </th>
                    <th class="text-h6 font-weight-regular">
                      = {{ showFloatValue(inputData.q) }} kN/m2
                    </th>
                    <td class="text-caption text-grey-darken-1">
                      {{ $t('results.info.q') }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-h6 font-weight-regular">
                      L
                    </th>
                    <th class="text-h6 font-weight-regular">
                      = {{ showFloatValue(inputData.l) }} m
                    </th>
                    <td class="text-caption text-grey-darken-1">
                      {{ $t('results.info.l') }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-h6 font-weight-regular">
                      Pmax
                    </th>
                    <th class="text-h6 font-weight-regular">
                      = {{ showFloatValue(inputData.pmax) }} kN
                    </th>
                    <td class="text-caption text-grey-darken-1">
                      {{ $t('results.info.pmax') }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-divider />
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column  pa-2"
            >
              <div class="d-flex py-3">
                <h2 class="pl-2 font-weight-regular">
                  {{ $t('results.result', [showFloatValue(result.loadInKN), showFloatValue(result.load), showFloatValue(result.firstPartLength)]) }}
                </h2>
              </div>
              <v-alert
                type="warning"
                variant="outlined"
                prominent
                class="mt-3"
              >
                <h4>{{ $t('results.warning') }}:</h4>
                <ul>
                  <li>
                    {{ $t('results.warning-1') }}
                  </li>
                  <li>
                    {{ $t('results.warning-2') }}
                  </li>
                  <li>
                    {{ $t('results.warning-3') }}
                  </li>
                </ul>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey-darken-1"
            variant="flat"
            @click="showDownloadDialog"
          >
            <v-icon left>
              mdi-download
            </v-icon>
            {{ $t('results.download.button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <result-info-card-component
      v-if="results.length"
      :schema="inputData.schemas"
    />
    <v-dialog
      v-model="downloadDialog"
      max-width="500px"
    >
      <v-card :loading="isDownloading">
        <v-toolbar
          density="compact"
          color="blue-grey-darken-1"
        >
          <v-toolbar-title>{{ $t('results.download.button') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="fileName"
              name="fileName"
              :label="$t('results.download.filename')"
              required
              autocomplete="off"
              :rules="fileNameRules"
              variant="outlined"
            >
              <template #append-inner>
                <v-chip size="small">
                  .docx
                </v-chip>
              </template>
            </v-text-field>
            <v-radio-group
              v-model="exportType"
              :label="$t('results.download.export-type')"
            >
              <v-radio
                :label="$t('results.download.export-type-simple')"
                value="simple"
              />
              <v-radio
                :label="$t('results.download.export-type-extended')"
                value="extended"
              />
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey-darken-1"
            text
            variant="outlined"
            @click="downloadDialog = false"
          >
            {{ $t('results.download.cancel') }}
          </v-btn>
          <v-btn
            color="blue-grey-darken-1"
            :loading="isDownloading"
            :disabled="isDownloading"
            variant="flat"
            @click="downloadFile"
          >
            <v-icon left>
              mdi-download
            </v-icon>
            {{ $t('results.download.download') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import ResultInfoCardComponent from './ResultInfoCardComponent.vue';

export default {
  components: {
    ResultInfoCardComponent,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    results: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      schemaImages: this.importAllImages(require.context('@/assets/images/schemas', false, /\.png$/)),
      threeDImages: this.importAllImages(require.context('@/assets/images/schemas/3d', false, /\.png$/)),
      infoImage: this.importAllImages(require.context('@/assets/images/schemas/info', false, /\.png$/)),
      downloadDialog: false,
      fileName: '',
      isDownloading: false,
      fileNameRules: [
        (v) => !!v || this.$t('results.download.filename-validation-empty'),
        (v) => !/[/:*?"<>|]/.test(v) || this.$t('results.download.filename-validation-invalid'),
      ],
      exportType: 'simple',
    };
  },
  computed: {
    result() {
      if (this.inputData.schemas) {
        return this.results.filter((result) => result.schema === this.inputData.schemas)[0];
      }

      return this.results[0];
    },
    ...mapGetters('sheets', ['sheetById']),
  },
  methods: {
    showFloatValue(val) {
      return val.toLocaleString('pl-PL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    importAllImages(r) {
      const images = {};
      r.keys().forEach((key) => {
        const number = key.match(/(\d+)\.png$/)[1];
        images[number] = r(key);
      });
      return images;
    },
    getSchemaImagePath(i) {
      return this.schemaImages[i];
    },
    get3dImagePath(i) {
      return this.threeDImages[i];
    },
    getInfoImagePath() {
      return this.infoImage[0];
    },
    showDownloadDialog() {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}-${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      this.fileName = `Hanger-wyniki-${formattedDate}`;
      this.downloadDialog = true;
    },
    async downloadFile() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.isDownloading = true;

        try {
          const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/hanger/download`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            },
            body: JSON.stringify({
              length: this.inputData.l,
              load: this.inputData.q,
              sheetTypeId: this.inputData.sheet,
              maxLoad: this.inputData.pmax,
              thickness: parseFloat(this.inputData.thickness.replace(',', '.')),
              schema: this.inputData.schemas,
              filename: this.fileName,
              type: this.exportType,
            }),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.fileName}.docx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('There was an error downloading the file:', error);
        } finally {
          this.isDownloading = false;
          this.downloadDialog = false;
        }
      }
    },
  },
};
</script>
