import { createI18n } from 'vue-i18n';
import pl from '@/locales/pl.json';

const messages = {
  pl,
};

const i18n = createI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  messages,
});

export default i18n;
