import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import CalculatorView from '../views/CalculatorView.vue';
import LoginView from '../views/LoginView.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/RegisterView.vue'),
    beforeEnter: async (to, from, next) => {
      try {
        store.commit('loading/SET_LOADING', true);
        // Dispatch the action to fetch countries before entering the register route
        await store.dispatch('countries/fetchCountries');
        next(); // Proceed to the route once countries are loaded
      } catch (error) {
        console.error('Error fetching countries:', error);
        next(false); // Cancel navigation if there’s an error
      } finally {
        store.commit('loading/SET_LOADING', false);
      }
    },
  },
  {
    path: '/',
    name: 'Calculator',
    component: CalculatorView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = store.getters['auth/isAuthenticated'];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next('/login');
    } else {
      store.commit('loading/SET_LOADING', true);
      await store.dispatch('sheets/fetchSheets');
      await store.dispatch('countries/fetchCountries');
      await store.dispatch('user/fetchUser');
      store.commit('loading/SET_LOADING', false);
      next();
    }
  } else {
    next();
  }
});

export default router;
