<template>
  <v-main class="bg-grey-lighten-2">
    <v-container
      class="d-flex flex-column align-center justify-center fill-height"
    >
      <v-card
        min-width="600"
        max-width="600"
      >
        <v-toolbar
          density="compact"
          color="blue-grey-lighten-1"
        >
          <v-toolbar-title>{{ $t('hanger') }}</v-toolbar-title>
          <v-spacer />

          <v-img
            src="@/assets/am_logo.svg"
            max-height="50"
            max-width="100"
            class="mr-3"
          />
        </v-toolbar>
        <v-form
          v-model="valid"
          @submit.prevent="loginAction"
        >
          <v-card-text>
            <v-alert
              type="info"
              variant="tonal"
              border="start"
              class="mb-4"
            >
              <p>
                {{ $t('login.info') }}
                <a
                  class="app-link text-decoration-none text-primary font-weight-medium d-inline-flex align-center"
                  href="https://arcelormittal.spaner.pl"
                  target="_blank"
                >{{ $t('login.app') }} SPANer <v-icon
                  size="x-small"
                  class="ml-1"
                >mdi-open-in-new</v-icon></a>
              </p>
            </v-alert>
            <v-divider
              v-if="error"
              class="mt-2 mb-4"
            />
            <v-alert
              v-if="error"
              v-model="showError"
              type="error"
              variant="tonal"
              border="start"
              class="mb-4"
            >
              {{ error }}
            </v-alert>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$t('login.email')"
              required
            />
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              :label="$t('login.password')"
              type="password"
              required
            />
          </v-card-text>
          <v-card-actions class="mb-0">
            <v-btn
              block
              :loading="loading"
              color="primary"
              type="submit"
              :disabled="!valid"
            >
              {{ $t('login.login') }}
            </v-btn>
          </v-card-actions>
          <v-card-actions class="justify-center mt-0">
            <v-btn
              text
              size="small"
              color="secondary"
              @click="$router.push('/register')"
            >
              {{ $t('login.no-account-register') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      valid: false,
      loading: false,
      error: '',
      showError: true,
      emailRules: [
        (v) => !!v || this.$t('login.empty-email'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login.invalid-email'),
      ],
      passwordRules: [
        (v) => !!v || this.$t('login.empty-password'),
      ],
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async loginAction() {
      try {
        this.loading = true;
        this.error = '';
        await this.login({ username: this.email, password: this.password });
        this.$router.push('/');
      } catch (error) {
        console.error('Login failed:', error);
        this.error = this.$t('login.login-failed');
        this.showError = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
