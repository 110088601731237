<template>
  <v-row class="d-flex flex-stretch">
    <v-col
      md="7"
      cols="12"
      class="d-flex flex-stretch"
    >
      <v-card
        class="d-flex flex-column justify-space-between"
        width="100%"
        density="compact"
      >
        <v-toolbar
          density="compact"
          color="blue-grey-darken-1"
        >
          <v-toolbar-title>{{ $t('form.title') }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pb-0">
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col md="6">
                <v-autocomplete
                  v-model="localCountry"
                  class="pb-2"
                  :rules="countryRules"
                  :label="$t('form.producer-country')"
                  variant="outlined"
                  :items="countries"
                  :item-value="item => item.name"
                  :item-title="item => item.name"
                />
                <v-autocomplete
                  v-model="localSheet"
                  class="pb-2"
                  :rules="sRules"
                  :label="$t('form.sheet-profile')"
                  variant="outlined"
                  :items="filteredSheets"
                  :custom-filter="filterSheets"
                  :item-value="item => item.id"
                  :item-title="item => item.name"
                >
                  <template #item="{ props, item }">
                    <v-list-item
                      v-bind="props"
                      :subtitle="item.raw.country.name"
                      :title="item.raw.name"
                    />
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="localThickness"
                  class="pb-2"
                  :rules="thicknessRules"
                  :label="$t('form.sheet-thickness')"
                  variant="outlined"
                  :items="thickness"
                >
                  <template #append-inner>
                    <v-chip size="small">
                      [mm]
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                md="6"
              >
                <v-text-field
                  v-model.number="localQ"
                  :rules="qRules"
                  :hint="$t('form.hints.q')"
                  label="q"
                  type="number"
                  variant="outlined"
                  step="0.01"
                  class="pb-2"
                  @blur="formatQ"
                >
                  <template #append-inner>
                    <v-chip size="small">
                      [kN/m2]
                    </v-chip>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model.number="localL"
                  :rules="lRules"
                  :hint="$t('form.hints.l')"
                  label="L"
                  type="number"
                  variant="outlined"
                  step="0.01"
                  class="pb-2"
                  @blur="formatL"
                >
                  <template #append-inner>
                    <v-chip size="small">
                      [m]
                    </v-chip>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model.number="localPmax"
                  :rules="pmaxRules"
                  :hint="$t('form.hints.pmax')"
                  label="Pmax"
                  type="number"
                  variant="outlined"
                  step="0.01"
                  class="pb-2"
                  @blur="formatPmax"
                >
                  <template #append-inner>
                    <v-chip size="small">
                      [kN]
                    </v-chip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                md="12"
                cols="12"
                class="pt-0 mt-0"
              >
                <v-radio-group
                  v-model="localSchemas"
                >
                  <v-row
                    justify="space-around"
                  >
                    <v-col
                      v-for="(schema, index) in schemas"
                      :key="schema.value"
                      cols="6"
                      md="2"
                      class="text-center mt-0"
                    >
                      <v-radio
                        :value="schema.value"
                        hide-details
                        class="d-flex flex-column"
                      >
                        <template #label>
                          <v-img
                            :src="require(`@/assets/images/schemas/piktograms/schemat${index + 1}.png`)"
                            height="30"
                            width="170"
                          />
                        </template>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey-darken-1"
            prepend-icon="mdi-calculator"
            variant="flat"
            @click="submitForm"
          >
            {{ $t('form.calculate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <info-card-component />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoCardComponent from './InfoCardComponent.vue';

export default {
  name: 'FormInputComponent',
  components: {
    InfoCardComponent,
  },
  props: {
    q: {
      type: Number,
      default: 1.07,
    },
    l: {
      type: Number,
      default: 3.5,
    },
    sheet: {
      type: Number,
      default: null,
    },
    pmax: {
      type: Number,
      default: 0.15,
    },
  },
  emits: ['updateFormData', 'invalidFormData'],
  data() {
    return {
      tab: 'one',
      valid: true,
      localQ: this.q,
      localL: this.l,
      localSheet: this.sheet,
      localCountry: null,
      localThickness: null,
      localSchemas: 1,
      localPmax: this.pmax,
      infoDialog: false,
      qRules: [
        (v) => !!v || this.$t('form.validation.required'),
        (v) => v > 0 || this.$t('form.validation.positive'),
      ],
      lRules: [
        (v) => !!v || this.$t('form.validation.required'),
        (v) => v > 0 || this.$t('form.validation.positive'),
      ],
      pmaxRules: [
        (v) => !!v || v === 0 || this.$t('form.validation.required'),
        (v) => v >= 0 || this.$t('form.validation.positive'),
      ],
      countryRules: [
        (v) => !!v || this.$t('form.validation.select-country'),
      ],
      sRules: [
        (v) => !!v || this.$t('form.validation.select-profile'),
      ],
      thicknessRules: [
        (v) => !!v || this.$t('form.validation.select-thickness'),
      ],
    };
  },
  computed: {
    ...mapGetters('sheets', ['sheets']),
    ...mapGetters('countries', ['countries']),
    ...mapGetters('user', ['getUserCountry']),
    filteredSheets() {
      if (!this.localCountry) return this.sheets;
      return this.sheets.filter((sheet) => sheet.country === this.localCountry);
    },
    thickness() {
      if (this.localSheet) {
        const { thickness } = this.sheets.find((sheet) => sheet.id === this.localSheet);
        return thickness.map((item) => parseFloat(item).toFixed(2).replace('.', ','));
      }
      return [];
    },
    schemas() {
      return [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
      ];
    },
  },
  watch: {
    localCountry() {
      this.localSheet = null;
      this.localThickness = null;
    },
    getUserCountry(newCountry) {
      if (!this.localCountry) {
        this.localCountry = newCountry;
      }
    },
  },
  mounted() {
    if (!this.localCountry && this.getUserCountry) {
      this.localCountry = this.getUserCountry;
    }
  },
  methods: {
    formatQ() {
      if (this.localQ) {
        this.localQ = parseFloat(this.localQ).toFixed(2);
      }
    },
    formatL() {
      if (this.localL) {
        this.localL = parseFloat(this.localL).toFixed(2);
      }
    },
    formatPmax() {
      if (this.localPmax) {
        this.localPmax = parseFloat(this.localPmax).toFixed(2);
      }
    },
    submitForm() {
      this.$refs.form.validate().then((valid) => {
        if (valid.valid) {
          this.$emit('updateFormData', {
            q: parseFloat(this.localQ),
            l: parseFloat(this.localL),
            sheet: this.localSheet,
            pmax: parseFloat(this.localPmax),
            country: this.localCountry,
            thickness: this.localThickness,
            schemas: this.localSchemas,
          });
        } else {
          this.$emit('invalidFormData');
        }
      });
    },
    filterSheets(value, search, item) {
      if (!search) return true;
      const nameMatches = item.raw.name.toLowerCase().includes(search.toLowerCase());
      return nameMatches;
    },
  },
};
</script>

<style scoped>
.text-none {
  text-transform: none;
}
</style>
