const countriesState = {
  countries: [],
  loading: false,
};

const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
};

const actions = {
  async fetchCountries({ commit }) {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/countries`, {
        method: 'GET',
      });
      const data = await response.json();
      commit('SET_COUNTRIES', data);
    } catch (error) {
      commit('error/SET_ERROR', 'Error while fetching data from API', { root: true });
      console.error('Error fetching countries:', error);
    }
  },
};

const getters = {
  countries: (state) => state.countries,
};

export default {
  namespaced: true,
  state: countriesState,
  mutations,
  actions,
  getters,
};
