// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles/main.sass';

// Vuetify
import { createVuetify } from 'vuetify';

const myCustomTheme = {
  themes: {
    light: {
      colors: {
        primary: '#78909C',
      },
    },
  },
};

export default createVuetify({
  theme: myCustomTheme,
});
