<template>
  <v-col
    md="5"
    cols="12"
    class="d-flex align-stretch"
  >
    <v-card
      width="100%"
      density="compact"
    >
      <v-tabs
        v-model="tab"
        bg-color="blue-grey-darken-1"
      >
        <v-tab value="one">
          {{ $t('form.2d-view') }}
        </v-tab>
        <v-tab value="two">
          {{ $t('form.3d-view') }}
        </v-tab>
        <v-tab value="three">
          {{ $t('form.info') }}
        </v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item
            value="one"
          >
            <v-img
              :src="get2dImage()"
              class="ma-1"
              cover
            />
          </v-tabs-window-item>

          <v-tabs-window-item value="two">
            <v-img
              :src="get3dImage()"
              class="ma-1"
              cover
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="three">
            <v-alert
              type="info"
              variant="tonal"
              border="start"
              v-html="$t('form.info-text')"
            />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      tab: 'one',
      infoImage: this.importAllImages(require.context('@/assets/images/schemas/info', false, /\.png$/)),
    };
  },
  methods: {
    get2dImage() {
      return this.infoImage[0];
    },
    get3dImage() {
      return this.infoImage[1];
    },
    importAllImages(r) {
      const images = {};
      r.keys().forEach((key) => {
        const number = key.match(/(\d+)\.png$/)[1];
        images[number] = r(key);
      });
      return images;
    },
  },
};
</script>
